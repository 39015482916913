import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hydro Power Engineering`}</h1>
    <h2>{`Hydropower Engineering Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1eOKR2Nwowhz1m-9uoH_3HJEVqoxXsDN_"
      }}>{`Download: Handbook of hydraulics for the solution of hydraulic problems by Horace Williams King`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1eVkJlkuOsgZzbsold-UYK6K0gM2oo6PS"
      }}>{`Download: Handbook of hydraulics 7th Ed. by Earnest F. Brater, Horace W King, James E. Lindell, C.Y. Wei`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1Rf_sNn9VdHS03lZnsOIaTtPqy3u43xWk"
      }}>{`Download: Layman’s guidebook on how to develop a small hydro site by European small hydropower association `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1y8rQnsheo5H1X57IXA-I-DCynWjv9_Lh"
      }}>{`Download: High head power plants by Emil Mosonyi Vol. 2A`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1O2qpbm-gSapvhJ1InEx-zWnTGhoURXCt"
      }}>{`Download: Hydropower engineering by C.C. Warnick  `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1hcHpEl5wvcb7aWwoqKUdiMxaSvqZc-20"
      }}>{`Download: Open channel hydraulics by Richard H. French`}</a></p>
    <h2>{`Hydropower engineering notes`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/34.-Hydropower-engineering-Principles-of-hydropower-engineering.pdf"
      }}>{`Hydropower engineering (Principles of hydropower engineering)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/35.-Hydropower-engineering-Hydropower-water-conveyance-system.pdf"
      }}>{`Hydropower engineering (Hydropower water conveyance system)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/36.-Hydropower-engineering-Hydropower-equipment-and-general-station.pdf"
      }}>{`Hydropower engineering (Hydropower equipment and general station)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Hydropower_enginneering-general-notes.pdf"
      }}>{`Hydropower enginneering general notes`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Instruction-manual-for-water-turbine.pdf"
      }}>{`Instruction manual for water turbine`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Hydel-Power-Potential-in-Pakistan.pdf"
      }}>{`Hydel Power Potential in Pakistan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Hydro-power-resources-of-Pakistan.pdf"
      }}>{`Hydro power resources of Pakistan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Hydropower-Engineering-Lecture-slides.pdf"
      }}>{`Hydropower Engineering (Lecture slides)`}</a></p>
    <h3>{`High head hydropower development notes`}</h3>
    <p>{`Data collection:`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/1.-High-head-hydropower-Data-Collection-and-data-processing-Load-demand-analysis-and-forecast.pdf"
      }}>{`1. High head hydropower Data Collection and data processing (Load demand analysis and forecast)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/2.-High-head-hydropower-Data-Collection-and-data-processing-Topography.pdf"
      }}>{`2. High head hydropower Data Collection and data processing (Topography)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/3.-High-head-hydropower-Data-Collection-and-data-processing-Hydrology.pdf"
      }}>{`3. High head hydropower Data Collection and data processing (Hydrology)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/4.-High-head-hydropower-Data-Collection-and-data-processing-Sediment.pdf"
      }}>{`4. High head hydropower Data Collection and data processing (Sediment)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/6.-High-head-hydropower-Data-Collection-and-data-processing-Seismicity.pdf"
      }}>{`6. High head hydropower Data Collection and data processing (Seismicity)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/7.-High-head-hydropower-Data-Collection-and-data-processing-Environmental-and-socioeconomical-aspects.pdf"
      }}>{`7. High head hydropower Data Collection and data processing (Environmental and socioeconomical aspects)`}</a></p>
    <p>{`Financial Analysis:`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/1.-High-head-hydropower-economic-and-financial-analysis-quantity-survey-and-const-estimation.pdf"
      }}>{`1. High head hydropower economic and financial analysis (quantity survey and const estimation)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/2.-High-head-hydropower-economic-and-financial-analysis-project-phasing-and-construction-schedule.pdf"
      }}>{`2. High head hydropower economic and financial analysis (project phasing and construction schedule)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/3.-High-head-hydropower-economic-and-financial-analysis-economic-and-financial-analysis.pdf"
      }}>{`3. High head hydropower economic and financial analysis (economic and financial analysis)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/4.-High-head-hydropower-economic-and-financial-analysis-long-term-system-expansion-planning.pdf"
      }}>{`4. High head hydropower economic and financial analysis (long term system expansion planning)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/5.-High-head-hydropower-economic-and-financial-analysis-hydrothermal-operation.pdf"
      }}>{`5. High head hydropower economic and financial analysis (hydrothermal operation)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/6.-High-head-hydropower-economic-and-financial-analysis-determination-of-SRMC-LRMC.pdf"
      }}>{`6. High head hydropower economic and financial analysis (determination of SRMC & LRMC)`}</a></p>
    <p>{`Layout and sizing:`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/1.-Layout-and-sizing-General.pdf"
      }}>{`1. Layout and sizing (General)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/2.-Layout-and-sizing-Sizing-of-structures.pdf"
      }}>{`2. Layout and sizing (Sizing of structures)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/5.-Layout-and-sizing-Hydraulic-modelling-in-hydropower-development.pdf"
      }}>{`5. Layout and sizing (Hydraulic modelling in hydropower development)`}</a></p>
    <p>{`Components:`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/High-head-hydro-power-components.pdf"
      }}>{`High head hydro power components`}</a></p>
    <h3>{`Low head hydropower development notes`}</h3>
    <p>{`Data collection:`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/1.-Data-Collection-Power-market-survey.pdf"
      }}>{`1. Data Collection (Power market survey)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/2.-Data-Collection-Topography.pdf"
      }}>{`2. Data Collection (Topography)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/3.-Data-Collection-Hydrology.pdf"
      }}>{`3. Data Collection (Hydrology)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/4.-Data-Collection-Sediment.pdf"
      }}>{`4. Data Collection (Sediment)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/6.-Data-Collection-Seismicity.pdf"
      }}>{`6. Data Collection (Seismicity)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/7.-Data-Collection-Environment-and-socioeconomical-aspects.pdf"
      }}>{`7. Data Collection (Environment and socioeconomical aspects)`}</a></p>
    <p>{`Engineering design:`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/1.-Civil-engineering-design.pdf"
      }}>{`1. Civil engineering design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/3.-Electrical-engineering-design.pdf"
      }}>{`3. Electrical engineering design`}</a></p>
    <p>{`Financial analysis:`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Financial-Analysis-Quantity-survey-and-cost-estimation.pdf"
      }}>{`Financial Analysis (Quantity survey and cost estimation)`}</a></p>
    <h2>{`Design of hydropower plant notes`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/conduits-culverts-and-pipes.pdf"
      }}>{`conduits, culverts and pipes`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/criteria-for-hydraulic-design-of-sediment-ejector-for-irrigation-and-power-channels.pdf"
      }}>{`criteria for hydraulic design of sediment ejector for irrigation and power channels`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/criteria-for-structural-design-of-barrages-and-weir.pdf"
      }}>{`criteria for structural design of barrages and weir`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/criteria-for-structural-design-of-penstock-part-I.pdf"
      }}>{`criteria for structural design of penstock part I`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/criteria-for-structural-design-of-penstock-part-II.pdf"
      }}>{`criteria for structural design of penstock part II`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/gates-and-valves-for-flow-control.pdf"
      }}>{`gates and valves for flow control`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Guide_on_How_to_Develop_a_Small_Hydropower_Plant.pdf"
      }}>{`Guide_on_How_to_Develop_a_Small_Hydropower_Plant`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Guideline-for-Design-of-SMALL-HYDROPOWER-PLANTS-HR1.pdf"
      }}>{`Guideline for Design of SMALL HYDROPOWER PLANTS – HR1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Guideline-for-Design-of-SMALL-HYDROPOWER-PLANTS-civil-works.pdf"
      }}>{`Guideline for Design of SMALL HYDROPOWER PLANTS civil works`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Guideline-for-Design-of-SMALL-HYDROPOWER-PLANTS-Hydraulic-and-structure-design.pdf"
      }}>{`Guideline for Design of SMALL HYDROPOWER PLANTS Hydraulic-and-structure-design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Guideline-for-Design-of-SMALL-HYDROPOWER-PLANTS-Reports-preparation.pdf"
      }}>{`Guideline for Design of SMALL HYDROPOWER PLANTS Reports preparation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Guideline-for-SELECTION_OF_TURBINE_AND_GOVERNING_SYSTEM.pdf"
      }}>{`Guideline for SELECTION_OF_TURBINE_AND_GOVERNING_SYSTEM`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Guidelines-for-small-hydro-development-general.pdf"
      }}>{`Guidelines for small hydro development general`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Guidelines-for-small-hydro-developments_Planning_and_Layouts.pdf"
      }}>{`Guidelines for small hydro developments_Planning_and_Layouts`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/hydraulic-design-of-spillways.pdf"
      }}>{`hydraulic design of spillways`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/hydraulic-loss-coefficients-for-culverts.pdf"
      }}>{`hydraulic loss coefficients for culverts`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/HydraulicDesign.pdf"
      }}>{`HydraulicDesign`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/hydraulics-design-of-barrages-and-weirs.pdf"
      }}>{`hydraulics design of barrages and weirs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/hydraulics-losses-in-pipes.pdf"
      }}>{`hydraulics losses in pipes`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/hydropower-development-in-pakistan.pdf"
      }}>{`hydropower development in pakistan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/hydropower-intakes.pdf"
      }}>{`hydropower intakes`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Optimum-Design-of-Penstock-for-Hydro-Projects.pdf"
      }}>{`Optimum Design of Penstock for Hydro Projects`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/penstock1.pdf"
      }}>{`penstock1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/penstock2.pdf"
      }}>{`penstock2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/penstock3.pdf"
      }}>{`penstock3`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/penstocks-and-tunnels.pdf"
      }}>{`penstocks and tunnels`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/planning-and-design-of-HPP-structures.pdf"
      }}>{`planning and design of HPP structures`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/planning-of-intake-structure-for-irrigation-and-hydropower.pdf"
      }}>{`planning of intake structure for irrigation and hydropower`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/River-hydraulics.pdf"
      }}>{`River hydraulics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/small-hydropower-development.pdf"
      }}>{`small hydropower development`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/small-hydropower-handbook.pdf"
      }}>{`small hydropower handbook`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/Water-Hammer-and-Surge-Tanks.pdf"
      }}>{`Water Hammer and Surge Tanks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/water-intake-structures-for-hydropower.pdf"
      }}>{`water intake structures for hydropower`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/weirs-experiments-and-formulas.pdf"
      }}>{`weirs experiments and formulas`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/welded-steel-penstocks-usbr.pdf"
      }}>{`welded steel penstocks usbr`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/design-of-kathai-ii-run-of-river-hydropower-plant-azad-kashmir/"
      }}>{`Design of Kathai II run of river hydropower plant Azad Kashmir`}</a></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      